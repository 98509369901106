<template>
  <div>
    <p class="text-2xl mb-6">Historique des changements de paliers</p>
    <Filters></Filters>
    <CwvThresholdChangesTable></CwvThresholdChangesTable>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import Filters from "@/components/web-perf/cwv/cwv-threshold-changes/filters/Filters.vue";
import CwvThresholdChangesTable from "@/components/web-perf/cwv/cwv-threshold-changes/tables/CwvThresholdChangesTable.vue";

export default {
  name: "ProjectsList",
  components: {
    Filters,
    CwvThresholdChangesTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
