import { render, staticRenderFns } from "./CwvThresholdChanges.vue?vue&type=template&id=a185a404&scoped=true&"
import script from "./CwvThresholdChanges.vue?vue&type=script&lang=js&"
export * from "./CwvThresholdChanges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a185a404",
  null
  
)

export default component.exports