<template>
  <v-card class="mb-6 pa-2">
    <v-row class="ma-3">
      <v-col cols="4">
        <site-group-filter store="webPerf"></site-group-filter>
      </v-col>
      <v-col cols="4">
        <site-filter store="webPerf"></site-filter>
      </v-col>
      <v-col cols="4">
        <device-filter store="webPerf"></device-filter>
      </v-col>
      <v-col cols="4">
        <page-type-filter store="webPerf"></page-type-filter>
      </v-col>
      <v-col cols="4">
        <metric-filter store="webPerf"></metric-filter>
      </v-col>
      <v-col cols="4">
        <evolution-filter store="webPerf"></evolution-filter>
      </v-col>
      <v-spacer />

      <v-col cols="2">
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
      <!--      <v-col cols="2" class="d-flex">
        <apply-filters
          title="Appliquer"
          :disabled="disabled"
          styles="height: 56px !important; width: 100% !important;"
          classList=""
        /> 
              
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";
import DeviceFilter from "@/components/common/filters/DeviceFilter.vue";
import MetricFilter from "@/components/web-perf/cwv/cwv-threshold-changes/filters/MetricFilter.vue";
import PageTypeFilter from "@/components/web-perf/cwv/cwv-threshold-changes/filters/PageTypeFilter.vue";
import EvolutionFilter from "@/components/web-perf/cwv/cwv-threshold-changes/filters/EvolutionFilter.vue";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ApplyFilters,
    DateFilter,
    SiteFilter,
    EvolutionFilter,
    DeviceFilter,
    CategoryFilter,
    SiteGroupFilter,
    MetricFilter,
    PageTypeFilter,
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  data() {
    return {
      disabled: true,
      loading: true,
      value: 25,
      panel: 0,
      pageType: [],
      pageTypeList: [
        { id: "article", name: "Article" },
        { id: "homepage", name: "Homepage" },
        { id: "section", name: "Section" },
      ],
    };
  },

  created() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style scoped></style>
